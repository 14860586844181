













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    ODamagesList: () => import("@/components/organisms/o-damages-list.vue"),
  },
});
